<template>
  <v-btn
    :style="{bottom: fabBottomVal, position: fabRelative ? 'relative': ''}"
    :color="background ? background : ''"
    :elevation="fabElevation"
    :fixed="fabFixed"
    :absolute="fabAbsolute"
    :disabled="fabDisabled"
    :loading="fabLoading"
    :bottom="fabBottom"
    :right="fabRight"
    :left="fabLeft"
    fab
    @click="clickEvent"
  >
    <v-icon
      :size="iconSize"
      :color="color ? color : ''"
    >
      {{ icon }}
    </v-icon>
  </v-btn>
</template>

<script>
  export default {
    props: {
      fabRelative: {
        type: Boolean,
        dafault: false,
      },
      fabFixed: {
        type: Boolean,
        dafault: true,
      },
      fabBottomVal: {
        type: String,
        dafault: '100px',
      },
      fabAbsolute: {
        type: Boolean,
        dafault: false,
      },
      fabDisabled: {
        type: Boolean,
        dafault: false,
      },
      fabLoading: {
        type: Boolean,
        dafault: false,
      },
      fabElevation: {
        type: Number,
        dafault: 10,
      },
      fabBottom: {
        type: Boolean,
        dafault: true,
      },
      fabRight: {
        type: Boolean,
        dafault: true,
      },
      fabLeft: {
        type: Boolean,
        dafault: false,
      },
      icon: {
        type: String,
        default: 'mdi-content-save',
      },
      iconSize: {
        type: String,
        default: 'large',
      },
      color: {
        type: String,
        default: 'white;',
      },
      background: {
        type: String,
        default: '#4caf50',
      },
    },
    data: function () {
      return {
        fab: null,
      }
    },
    methods: {
      async clickEvent () {
        this.$emit('click-event')
      },
    },
  }
</script>
